import axios from 'axios';
import { useEffect, useState } from 'react';

export interface Profile {
  firstName: string;
  lastName: string;
  email: string;
  picture: string;
}

const useFetchProfile = (token: string | undefined) => {
  const [profile, setProfile] = useState<Profile>({
    firstName: '',
    lastName: '',
    email: '',
    picture: '',
  });

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!token) {
      setError('Token is undefined');
      setLoading(false);
      return;
    }

    const fetchProfile = async () => {
      try {
        setLoading(true);

        const createResponse = await axios.post('/api/create-account');

        if (!createResponse?.data?.success) {
          setError('Failed to create account');
          return;
        }

        const accountInfoResponse = await axios.post('/api/get-account');

        if (!accountInfoResponse?.data?.success) {
          setError('Failed to get account info');
          return;
        }

        const profileData = accountInfoResponse?.data?.data;

        if (!profileData) {
          setError('No profile data found');
          return;
        }

        const { firstName, lastName, email, picture } = profileData;

        let imageUrl = '';

        if (typeof picture === 'string' && picture?.trim() !== '') {
          try {
            const proxyImage = await axios.get(
              `/api/proxy-image?url=${encodeURIComponent(picture)}`
            );
            imageUrl = proxyImage?.config?.url || '';
          } catch (proxyError) {
            imageUrl = '';
          }
        }
        setProfile({
          firstName,
          lastName,
          email,
          picture: imageUrl,
        });

        localStorage.setItem(
          'profile',
          JSON.stringify({
            firstName,
            lastName,
            email,
            imageUrl,
          })
        );
        // store in local storage
        setError(null);
      } catch (error) {
        setError('Failed to fetch profile');
      } finally {
        setLoading(false);
      }
    };

    fetchProfile();
  }, [token]);

  return { profile, loading, error };
};

export default useFetchProfile;
