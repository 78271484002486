import Image, { ImageProps } from 'next/image';

const GoogleProfileImage = ({
  width = 25,
  height = 25,
  ...props
}: ImageProps) => (
  <Image className="rounded-full" {...props} width={width} height={height} />
);

export default GoogleProfileImage;
