import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import Cookies from 'js-cookie';
import useFetchProfile, { Profile } from 'hooks/useFetchProfile';
import { GoogleOneTapLogin } from '@alphafounders/googleonetap';

interface ProfileContextProps {
  profile: Profile;
  loading: boolean;
  error: string | null;
  isLoggedIn: boolean;
  setIsLoggedIn: (isLoggedIn: boolean) => void;
}

const ProfileContext = createContext<ProfileContextProps | undefined>(
  undefined
);

export const isEnableGoogleOneTapLogin =
  process.env.NEXT_PUBLIC_ENABLE_GOOGLE_LOGIN === 'true';

export const ProfileProvider = ({ children }: { children: ReactNode }) => {
  const token = Cookies.get('rc-google-id-token');
  const { profile, loading, error } = useFetchProfile(token);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    return setIsLoggedIn(!!token && !error);
  }, [isLoggedIn, token, error]);

  const onSuccess = (token: any) => {
    setIsLoggedIn(true);
    Cookies.set('rc-google-id-token', token, {
      expires: 1 / 24,
      path: '/',
      secure: true,
    });
  };

  return (
    <>
      {isEnableGoogleOneTapLogin && !isLoggedIn && (
        <GoogleOneTapLogin
          clientId={process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID ?? ''}
          onSuccess={(token: string) => {
            onSuccess(token);
          }}
          onError={() => console.log('this  is error')}
          isLoggedIn={isLoggedIn}
        />
      )}
      <ProfileContext.Provider
        value={{ profile, loading, error, isLoggedIn, setIsLoggedIn }}
      >
        {children}
      </ProfileContext.Provider>
    </>
  );
};

export const useProfile = () => {
  const context = useContext(ProfileContext);

  if (!context) {
    throw new Error('useProfile must be used within a ProfileProvider');
  }

  return context;
};

export const MockProfileProvider = ({ children }: { children: ReactNode }) => {
  const mockProfile = {
    email: '',
    firstName: 'John',
    lastName: 'Doe',
    picture: '',
  };
  const loading = false;

  return (
    <ProfileContext.Provider
      value={{
        profile: mockProfile,
        loading,
        error: null,
        isLoggedIn: true,
        setIsLoggedIn: () => {},
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
};
